<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>支票申请</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>支票申请</span>
            <div class="sub-merchants-container">
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议名称',pid.cid)}}</div>
                    <el-select
                        ref="pName"
                            v-model="pName"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            @change="proChange"
                            filterable>
                        <el-option
                                v-for="(item, index) in projectOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                            v-model="society"
                            :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                            @visible-change="clickProject">
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                    <el-input
                            v-model="pid.time"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                    <el-input
                            v-model="pid.chairman"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">特殊事项</div>
                    <el-input
                            type="textarea"
                            resize="none"
                            :rows="5"
                            v-model="shiyou"
                            placeholder="请输入特殊事项"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">付款金额</div>
                    <el-input
                            v-model="amounts"
                            @mousewheel.native.prevent
                            type="number"
                            placeholder="请输入付款金额"
                    ></el-input>
                    <div class="money">万元</div>
                </div>
                <div class="input-item">
                    <div class="input-item-title">收款单位</div>
                    <el-input v-model="payee" placeholder="请输入收款单位"></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">收款单位开户行</div>
                    <el-input
                            v-model="bank_name"
                            placeholder="请输入收款单位开户行"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">收款单位账号</div>
                    <el-input
                            v-model="bank_code"
                            type="number"
                            placeholder="请输入收款单位账号"></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">上传支持性文件</div>
                    <div>
                        <div v-if="files.length > 0" class="upfile-list">
                            <ul v-for="(file, index) in files" :key="index">
                                <li class="flex-center between-box">
                                    <a class="ovHide" :href="file.filePath | filePath" target="_blank">{{ file.fileName }}</a>
                                    <i class="el-icon-delete" @click="closeFile(index)"></i>
                                </li>
                            </ul>
                        </div>
                        <el-upload
                                :action="uploadPath"
                                :show-file-list="false"
                                :multiple="true"
                                :data="QiniuData"
                                :on-error="uploadError"
                                :on-success="uploadSuccess"
                                :before-upload="beforeAvatarUpload"
                        >
                            <el-button size="small" type="primary">点击上传文件</el-button>
                            <!--<div slot="tip" class="el-upload__tip">只能上传PDF文件</div>-->
                        </el-upload>
                    </div>
                </div>

                <approvalList
                        :typeClassify="7"
                        :isNoHave="!id"
                        :approvalMembers="approvalMembers"
                        :copyMembers="copyMembers"
                        @setMember="setMember"
                        @setApproval="setApproval"
                        :society="society"
                ></approvalList>

                <el-button type="primary" @click="subApproval" :loading="requestState" :disabled="requestState">提交
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import approvalList from "../../components/approvalList.vue";
    import {genUpToken,qiniuaddr,uploadPath} from "../../tools/CryptoJS";
    import {webUrl} from "../../tools";

    export default {
        components: {
            ElButton,
            ElInput,
            approvalList,
        },
        name: "otherPay",
        data() {
            return {
                id: "",
                sign_code: "",
                //  学会数据
                society: "",
                societyOptions: [],
                //  会议数据
                pid: "",
                pName: "",
                projectOptions: [],
                //                xid: '',
                //                htOptions: [],
                shiyou: "",
                amounts: "",
                payee: "",
                bank_name: "",
                bank_code: "",
                shenpi: "",
                copy: "",
                QiniuData: {
                    fname: "", //  文件名
                    key: "", //文件名字处理
                    token: "", //七牛云token
                },
                qiniuaddr:qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
                uploadPath:uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
                files: [],
                approvalMembers: [],
                copyMembers: [],
                requestState: false
            };
        },
        created() {
            let token = genUpToken();
            this.QiniuData.token = token;
            this.id = this.$route.query.id ? this.$route.query.id : '';
            this.$emit("setIndex", [4, 14]);
            this.init();
        },
        methods: {
            //  提交审核
            subApproval() {
                if (!this.pid) {
                    this.$message.error(this.$refs.pName.placeholder);
                    return;
                }
                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }
                //                if (!this.xid) {
                //                    this.$message.error("请选择下游合同")
                //                    return
                //                }
                if (!this.shiyou) {
                    this.$message.error("请输入特殊事项");
                    return;
                }
                if (!this.amounts) {
                    this.$message.error("请选择付款金额");
                    return;
                }
                if (!this.payee) {
                    this.$message.error("请选择收款单位");
                    return;
                }
                if (!this.bank_name) {
                    this.$message.error("请输入开户行");
                    return;
                }
                if (!this.bank_code) {
                    this.$message.error("请输入银行账户");
                    return;
                }

                //                if (this.files.length<= 0) {
                //                    this.$message.error("请上传附件")
                //                    return
                //                }

                if (this.shenpi.indexOf("-1") !== -1) {
                    this.$message.error("请完善财务人员");
                    return;
                }
                let arr = this.shenpi.split(",");
                if (arr[0] === arr[1]) {
                    this.$message.error("审批人与财务人员不能相同");
                    return;
                }
                this.requestState = true
                //添加
                this.$api.apiContent
                    .approvalTSFk({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        pid: this.pid.id,
                        //                    xid: this.xid,
                        sign_code: this.sign_code,
                        amount: this.amounts,
                        payee: this.payee,
                        bank_name: this.bank_name,
                        bank_code: this.bank_code,
                        shiyou: this.shiyou,
                        shenpi: this.shenpi,
                        copy: this.copy,
                        files: JSON.stringify(this.files),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                pid: this.pid.id,
                                //                        xid: this.xid,
                                sign_code: this.sign_code,
                                amount: this.amounts,
                                payee: this.payee,
                                bank_name: this.bank_name,
                                bank_code: this.bank_code,
                                shiyou: this.shiyou,
                                shenpi: this.shenpi,
                                copy: this.copy,
                                files: JSON.stringify(this.files),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.requestState = false
                        this.$message.success(res.msg);
                        this.$router.push("send");
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },
            //  选择会议
            proChange(index) {
                let value = this.projectOptions[index];
                value.time =
                    this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                    " 至 " +
                    this.$tools.ZHDate(new Date(value.end_time * 1000));
                this.pName = value.name;
                this.pid = value;
                this.society = "";
                //                this.xid = ''
                this.getSociety();
                //                this.getContractList()
            },

            //  选择所属学会点开后没选择
            clickProject(view) {
                if (!this.pid && view) {
                    this.$message.error("请选择会议名称");
                }
            },
            //  获取合同
            getContractList() {
                this.$api.apiContent
                    .getContractList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map((item) => {
                            item.name =
                                item.corporate_name +
                                "(" +
                                this.$tools.ZHDate(new Date(item.add_time * 1000), 6) +
                                ")";
                        });
                        this.htOptions = res.data;
                        if (res.data.length === 1) this.xid = res.data[0].id;
                    })
                    .catch(() => {
                        this.htOptions = [];
                    });
            },
            //  获取项目
            getSociety() {
                this.$api.apiContent
                    .getSocietyFromPro({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.societyOptions = res.data;
                        if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                    })
                    .catch(() => {
                        this.societyOptions = [];
                    });
            },
            //  获取学会
            init() {
                this.$api.apiContent
                    .getProject({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item => {
                            item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(item.end_time * 1000)) + '/' + item.chairman + '/' + item.name
                        })
                        this.projectOptions = res.data;
                    })
                    .catch(() => {
                        this.projectOptions = [];
                    });
                if (this.id) {
                    this.$api.apiContent
                        .getTeshu({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                            this.copyMembers = res.data.copy;
                            this.copy = res.data.copy
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",");
                            this.society = res.data.sid;
                            //                        this.xid = res.data.xid
                            this.sign_code = res.data.sign_code;
                            res.data.project.time =
                                this.$tools.ZHDate(
                                    new Date(res.data.project.start_time * 1000)) +
                                " 至 " +
                                this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                            res.data.project.id = res.data.pid;
                            this.pid = res.data.project;
                            this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' + res.data.project.name;
                            this.shiyou = res.data.shiyou;
                            this.amounts = parseFloat(Number(res.data.amount).toFixed(6));
                            this.payee = res.data.payee;
                            this.bank_name = res.data.bank_name;
                            this.bank_code = res.data.bank_code;
                            this.files = res.data.files;
                            this.getSociety();
                            //                        this.getContractList()
                        })
                        .catch(() => {
                        });
                }
            },
            //  上传成功
            uploadSuccess(response, file, fileList) {
                console.log(fileList);
                this.files.push({
                    filePath: `${this.qiniuaddr}/${response.key}`,
                    //在这里你就可以获取到上传到七牛的外链URL了
                    fileName: file.name,
                });

                this.loading.close();
            },
            closeFile(position) {
                this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.files.splice(position, 1);
                    })
                    .catch();
            },
            //  上传失败
            uploadError() {
                this.loading.close();
                this.$message({
                    message: "上传出错，请重试！",
                    type: "error",
                    center: true,
                });
            },
            beforeAvatarUpload(file) {
                //                const isJPG = file.type === 'application/pdf';
                //                if (!isJPG) {
                //                    this.$message.error('只能上传PDF格式!');
                //                    return
                //                }
                //这里的key给加上了时间戳，目的是为了防止上传冲突
                this.QiniuData.fname = file.name;
                this.QiniuData.key = `${new Date().getTime()}${file.name}`;
                this.loading = this.$loading({
                    lock: true,
                    text: "上传中，请稍后",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            },
            //  返回
            backPage() {
                this.$tools.goBack();
            },
            //审批人
            setApproval(members) {
                this.shenpi = members;
            },
            //抄送人
            setMember(members) {
                this.copy = members;
            },
        },
        filters: {
            filePath(url) {
               return webUrl(url)
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .sub-merchants-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 40px 25px;
            min-height: 800px;

            span {
                color: #000000;
                font-size: 24px;
            }

            .sub-merchants-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                .input-item {
                    display: flex;
                    margin-bottom: 20px;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;
                    .upfile-list {
                        width: 400px;
                        li {
                            height: 31px;
                            border-bottom: 1px dashed #ccc;

                            a {
                                display: block;
                                width: 350px;
                                height: 30px;
                                line-height: 30px;
                                color: #3b77e7;
                            }

                            .el-icon-delete {
                                font-size: 16px;
                                cursor: pointer;

                                &:hover {
                                    color: #3b77e7;
                                }
                            }
                        }
                    }
                    .money {
                        flex-shrink: 0;
                        margin-left: 10px;
                    }
                    .input-item-title {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        color: #000000;
                        flex-shrink: 0;
                    }

                    .input-item-desc {
                        width: 20%;
                        align-self: flex-start;
                        margin-top: 10px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        flex-shrink: 0;
                    }

                    .el-input,
                    .el-select,
                    .el-textarea,
                    .el-radio-group,
                    .el-date-editor,
                    .el-upload {
                        flex-grow: 1;
                        resize: none;
                    }
                }

                > .el-button {
                    margin-top: 30px;
                    align-self: center;
                    width: 40%;
                }
            }
        }
    }
</style>
